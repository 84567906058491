// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails imports
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// jQuery setup
import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

//

require("@popperjs/core/dist/umd/popper.min.js");
let OverlayScrollbarsGlobal = require('overlayscrollbars');
window.OverlayScrollbarsGlobal = OverlayScrollbarsGlobal;
require("bootstrap/dist/js/bootstrap.min.js");
require('./main.js');

// Plugins
require("../packs/select2.full.min.js");
require("../packs/owl.carousel.min.js");
require("../packs/lightgallery.min");

// FontAwesome
require("@fortawesome/fontawesome-free/js/all");

// Cookies
import Cookies from "../packs/cookie";
window.Cookies = Cookies;

// Stylesheets
require("../stylesheets/application.scss");

// import 'stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.apiurl = '/api/v1';
window.directUrl = '/home/direction'
window.detailUrl = '/map/places'
window.apiauthen = 'Bearer qUT17DipLFXwcb-28ZdQZ4WaCxGAljJ5hNmT9YLKMm8';
window.limit = 100;

require('particles.js/particles.js');

import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/github.css'; // example theme

hljs.registerLanguage('python', python);

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightBlock(block);
  });
});

import ClipboardJS from 'clipboard';

document.addEventListener('DOMContentLoaded', () => {
  const clipboard = new ClipboardJS('[data-clipboard-target]');
  clipboard.on('success', function(e) {
  });
  clipboard.on('error', function(e) {
  });
});

let Swal = require('sweetalert2');
window.Swal = Swal;

require('../js/loading-bar.js');
  // // BEGIN
  // $(document).on('turbolinks:click', () =>{
  //   alert('click');
  // });
  // $(document).on('turbolinks:before-visit', () =>{
  //   alert('before-visit');
  // });
  // $(document).on('turbolinks:request-start', () =>{
  //   alert('request-start');
  // });
  // $(document).on('turbolinks:visit', () =>{
  //   alert('visit');
  // });
  // $(document).on('turbolinks:request-end', () =>{
  //   alert('request-end');
  // });
  // $(document).on('turbolinks:before-cache', () =>{
  //   alert('before-cache');
  // });
  // // **
  // // * execute turbolinks:load
  // // **
  // $(document).on('turbolinks:before-render', () =>{
  //   alert('before-render');
  // });
  // $(document).on('turbolinks:render', () =>{
  //   alert('render');
  // });
  // $(document).on('turbolinks:load', () =>{
  //   alert('load');
  // });
  // // END 