import consumer from "./consumer"

consumer.subscriptions.create("ScanJobsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to ScanJobsChannel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Disconnected from ScanJobsChannel");
  },

  received(event) {
    const currentPath = window.location.pathname;
    const match = currentPath.match(/^\/scan_jobs\/(\d+)$/);
    const scan_job_id = event.data.scan_job_id
    if (match && parseInt(match[1], 10) === scan_job_id) {
      console.log("Received event:", event);
      switch (event.type) {
        case "update_progress":
          if (event.data.additional_data.progress) {updateProgressBar(event.data.additional_data.progress, scan_job_id);}
          break;
        case "scan_message":
        case "scan_started":
        case "scan_error":
        case "scan_revoked":
        
          appendScanMessage(event, scan_job_id);
          break;
        case "scan_finished":
          appendScanMessage(event, scan_job_id);
          finishScanJob(scan_job_id);
          break;
        case "camera_added":
          // addNewCameraToDashboard(event.data.camera, event.data.scan_job_id);
          appendScanMessage(event, scan_job_id);
          increaseBoxNumber(`total-online-${scan_job_id}`);
          break;
        case "address_scanned":
          appendScanMessage(event, scan_job_id);
          increaseBoxNumber(`total-devices-${scan_job_id}`);
          break;
        case "vulnerability_found":
          addVulnerabilityToDashboard(event.camera, event.vulnerability, scan_job_id);
          break;
        
      
        default:
          console.error("Unknown event type:", event.type);
      }
    }
  }
});

function updateProgressBar(progress, scan_job_id) {
  const progressBar = document.getElementById(`progress-bar-${scan_job_id}`);
  progress = Math.round(progress * 100);
  if (progressBar) {
    progressBar.style.width = `${progress}%`;
    // progressBar.textContent = `${Math.round(data.progress * 100)}%`;
  }
  const progressText = document.getElementById(`progress-text-${scan_job_id}`);
  if (progressText) {
    progressText.textContent = `${progress}%`;
  }

  if (ldBarScanJob){
    ldBarScanJob.set(progress);
  } 
}

function addNewCameraToDashboard(camera, scan_job_id) {
  const cameraList = document.getElementById(`camera-list-${scan_job_id}`);
  const cameraItem = document.createElement("div");
  cameraItem.dataset.ip = camera.ip_str;
  cameraItem.dataset.port = camera.port;
  cameraItem.dataset.is_active = camera.is_active;
  const existingCamera = Array.from(cameraList.children).find(item =>
    item.dataset.ip === String(camera.ip_str) && item.dataset.port === String(camera.port)
  );
  if (existingCamera) {
    // update the content
    existingCamera.textContent = `${camera.product_name} found at: ${camera.ip_str}:${camera.port}`;
    existingCamera.dataset.is_active = camera.is_active;
    return;
  }
  cameraItem.textContent = `${camera.product_name} found at: ${camera.ip_str}:${camera.port}`;
  cameraList.appendChild(cameraItem);
  const totalDevicesElement = document.getElementById(`total-devices-${scan_job_id}`);
  if (totalDevicesElement) {
    totalDevicesElement.textContent = parseInt(totalDevicesElement.textContent) + 1;
  }
  const totalOnlineElement = document.getElementById(`total-online-${scan_job_id}`);
  if (totalOnlineElement) {
    totalOnlineElement.textContent = parseInt(totalOnlineElement.textContent) + 1;
  }
}

function cameraOnlineFound(camera, scan_job_id) {
  increaseBoxNumber(`total-online-${scan_job_id}`);
}


function increaseBoxNumber(box_id, increase=1) {
  const boxElement = document.getElementById(box_id);
  if (boxElement) {
    boxElement.textContent = parseInt(boxElement.textContent) + increase;
    boxElement.style= "font-weight: 700;";
  }
}

function addVulnerabilityToDashboard(camera, vulnerability, scan_job_id) {
  const vulnList = document.getElementById(`vulnerability-list-${scan_job_id}`);
  const vulnTypeList = document.getElementById(`vulnerability-type-list-${scan_job_id}`);
  const totalVulnsElement = document.getElementById(`total-vulnerabilities-${scan_job_id}`);
  const countVulnsElement = document.getElementById(`count-vulnerabilities-${scan_job_id}`);
  
  // const existingVuln = Array.from(vulnList.children).find(item => 
  //   item.dataset.ip === camera_vulnerability.camera.ip_str && 
  //   item.dataset.port === camera_vulnerability.camera.port && 
  //   item.dataset.vulnId === camera_vulnerability.vulnerability.id
  // );
  const existingVulnType = Array.from(vulnTypeList.children).find(item =>
    item.dataset.vulnId === String(vulnerability.id)
  );
  if (!existingVulnType) {
    const vulnTypeItem = document.createElement("div");
    console.log('Vulnerability:', vulnerability);
    vulnTypeItem.innerHTML = `
      <a href="/vulnerabilities/${vulnerability.id}" style="text-decoration: none">
      <span class="badge-severity badge-${vulnerability.cvss_severity_level}">${vulnerability.name}</span>
      </a>
    `;
    vulnTypeItem.dataset.vulnId = vulnerability.id;
    vulnTypeList.appendChild(vulnTypeItem);

    if (totalVulnsElement) {
      totalVulnsElement.textContent = parseInt(totalVulnsElement.textContent) + 1;
      totalVulnsElement.style="font-weight: 700;";
    }
  } else {
  }
  const vulnItem = document.createElement("div");
  vulnItem.innerHTML = `
    <i class="fas fa-bug text-danger"></i>  
    <a href="/vulnerabilities/${ vulnerability.id }" class="text-danger" target="_blank">${ vulnerability.name }</a>
    <span class="float-end">
      ${ camera.ip_str }:${ camera.port }
      <a href="/cameras/${ camera.id }" style="color: black;" title="Go to camera detail" data-bs-toggle="tooltip" data-bs-placement="top" target="_blank"><i class="fas fa-link"></i></a>
      <a href="http://${ camera.ip_str }:${ camera.port }" style="color: black;" title="Go to ${ camera.ip_str }:${ camera.port }" data-bs-toggle="tooltip" data-bs-placement="top" target="_blank" ><i class="bi bi-globe"></i></a>
    </span>
  `;
  vulnItem.className = "hover-bg-secondary px-2 py-1 mb-1"; 
  vulnItem.dataset.ip = camera.ip_str;
  vulnItem.dataset.port = camera.port;
  vulnItem.dataset.vulnId = vulnerability.id;
  vulnList.appendChild(vulnItem);
  if (countVulnsElement) {
    countVulnsElement.textContent = parseInt(countVulnsElement.textContent) + 1;
    countVulnsElement.style="font-weight: 700;";
  }
}

function updateScanLog(log, scan_job_id) {
  const logWindow = document.getElementById(`scan-log-${scan_job_id}`);
  const logEntry = document.createElement('div');
  logEntry.textContent = log.message;
  logWindow.appendChild(logEntry);
  logWindow.scrollTop = logWindow.scrollHeight; // Auto-scroll to the bottom
}

function appendScanMessage(scan_job_event, scan_job_id) {
  const data = scan_job_event.data;
  const created_at = data.created_at;
  const messageElement = document.getElementById(`scan-log-${scan_job_id}`);
  if (messageElement) {
    const messageEntry = document.createElement('div');
    messageEntry.className = 'log-entry hover-bg-secondary mb-1 px-2';
    messageEntry.innerHTML = `
      <span class="log-level 
        text-${data.log_level == "INFO" ? 'secondary' : '' }${data.log_level == "WARNING" ? 'warning' : '' }${data.log_level == "ERROR" ? 'danger' : '' }
      ">
        ${ data.log_level == "INFO" ? '<i class="bi bi-info-circle"></i>' : '' }
        ${ data.log_level == "WARNING" ? '<i class="bi bi-exclamation-triangle-fill"></i>' : '' }
        ${ data.log_level == "ERROR" ? '<i class="bi bi-exclamation-octagon-fill"></i>' : '' }
      </span>
      <span class="log-message">
        ${data.log_message}
      </span>
      <span class="log-timestamp float-end">
        ${new Date().toLocaleString()}
      </span>
    `;
    // old time: ${new Date(scan_job_event.created_at).toLocaleString()}
    messageElement.appendChild(messageEntry);
    messageElement.scrollTop = messageElement.scrollHeight; // Auto-scroll to the bottom
  }
}

function updateLatestAlert(alert, scan_job_id) {
  const alertWindow = document.getElementById(`latest-alerts-${scan_job_id}`);
  const alertEntry = document.createElement('div');
  alertEntry.textContent = alert.message;
  alertWindow.appendChild(alertEntry);
  alertWindow.scrollTop = alertWindow.scrollHeight; // Auto-scroll to the bottom
}
function finishScanJob(scan_job_id) {
  const progressResult = document.getElementById(`progress-result-${scan_job_id}`);
  if (progressResult) {
    while (progressResult.firstChild) {
      progressResult.removeChild(progressResult.firstChild);
    }
    const completedBadge = document.createElement('span');
    completedBadge.className = 'badge badge-soft-success';
    completedBadge.textContent = 'Completed';
    progressResult.appendChild(completedBadge);
    const durationElement = document.getElementById(`duration-${scan_job_id}`);
    if (durationElement){
      durationElement.dataset.finished = 'true';
      durationElement.dataset.finishedAt = Math.floor(Date.now() / 1000);
      const startedAt = parseInt(durationElement.dataset.startedAt, 10);
      const finishedAt = parseInt(durationElement.dataset.finishedAt, 10);
      if (startedAt && finishedAt) {
        const duration = finishedAt - startedAt;
        console.log('Duration:', duration);
        updateDuration(formatDuration(duration), scan_job_id);
      } else {
        updateDuration("Unknown", scan_job_id);
      }
    }
    
    if (window.scanJobIntervals && window.scanJobIntervals[scan_job_id]) {
      clearInterval(window.scanJobIntervals[scan_job_id]);
      delete window.scanJobIntervals[scan_job_id];
      
    }
  } else {
    console.error('progress-result not found');
  }
}
function updateDuration(duration, scan_job_id) {
  const durationElement = document.getElementById(`duration-${scan_job_id}`);
  if (durationElement) {
    durationElement.textContent = duration;
  }
}
function formatDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m : ${remainingSeconds}s`;
}
