(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
  // Generated by LiveScript 1.3.1
  var presets, simpleStr, wrap, slice$ = [].slice, toString$ = {}.toString;
  presets = require('./presets').presets;
  simpleStr = function(arr){
    return arr.join('');
  };
  wrap = function(content){
    return "data:image/svg+xml;base64," + btoa(content);
  };
  (function(){
    var make, handler, ldBar;
    make = {
      head: function(viewBox){
        return "<?xml version=\"1.0\" encoding=\"utf-8\"?>\n        <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"" + viewBox + "\">";
      },
      gradient: function(dir, dur){
        var colors, ret, len, gx, gy, x, y, i$, i, idx;
        dir == null && (dir = 45);
        dur == null && (dur = 1);
        colors = slice$.call(arguments, 2);
        ret = [this.head("0 0 100 100")];
        len = colors.length * 4 + 1;
        dir = dir * Math.PI / 180;
        gx = Math.pow(Math.cos(dir), 2);
        gy = Math.sqrt(gx - Math.pow(gx, 2));
        if (dir > Math.PI * 0.25) {
          gy = Math.pow(Math.sin(dir), 2);
          gx = Math.sqrt(gy - Math.pow(gy, 2));
        }
        x = gx * 100;
        y = gy * 100;
        ret.push("<defs><linearGradient id=\"gradient\" x1=\"0\" x2=\"" + gx + "\" y1=\"0\" y2=\"" + gy + "\">");
        for (i$ = 0; i$ < len; ++i$) {
          i = i$;
          idx = i * 100 / (len - 1);
          ret.push("<stop offset=\"" + idx + "%\" stop-color=\"" + colors[i % colors.length] + "\"/>");
        }
        ret.push("</linearGradient></defs>\n<rect x=\"0\" y=\"0\" width=\"400\" height=\"400\" fill=\"url(#gradient)\">\n<animateTransform attributeName=\"transform\" type=\"translate\" from=\"-" + x + ",-" + y + "\"\nto=\"0,0\" dur=\"" + dur + "s\" repeatCount=\"indefinite\"/></rect></svg>");
        return wrap(ret.join(""));
      },
      stripe: function(c1, c2, dur){
        var ret, i;
        c1 == null && (c1 = '#b4b4b4');
        c2 == null && (c2 = '#e6e6e6');
        dur == null && (dur = 1);
        ret = [this.head("0 0 100 100")];
        ret = ret.concat([
          "<rect fill=\"" + c2 + "\" width=\"100\" height=\"100\"/>", "<g><g>", (function(){
            var i$, results$ = [];
            for (i$ = 0; i$ < 13; ++i$) {
              i = i$;
              results$.push(("<polygon fill=\"" + c1 + "\" ") + ("points=\"" + (-90 + i * 20) + ",100 " + (-100 + i * 20) + ",") + ("100 " + (-60 + i * 20) + ",0 " + (-50 + i * 20) + ",0 \"/>"));
            }
            return results$;
          }()).join(""), "</g><animateTransform attributeName=\"transform\" type=\"translate\" ", "from=\"0,0\" to=\"20,0\" dur=\"" + dur + "s\" repeatCount=\"indefinite\"/></g></svg>"
        ].join(""));
        return wrap(ret);
      },
      bubble: function(c1, c2, count, dur, size, sw){
        var ret, i$, i, idx, x, r, d;
        c1 == null && (c1 = '#39d');
        c2 == null && (c2 = '#9cf');
        count == null && (count = 15);
        dur == null && (dur = 1);
        size == null && (size = 6);
        sw == null && (sw = 1);
        ret = [this.head("0 0 200 200"), "<rect x=\"0\" y=\"0\" width=\"200\" height=\"200\" fill=\"" + c1 + "\"/>"];
        for (i$ = 0; i$ < count; ++i$) {
          i = i$;
          idx = -(i / count) * dur;
          x = Math.random() * 184 + 8;
          r = (Math.random() * 0.7 + 0.3) * size;
          d = dur * (1 + Math.random() * 0.5);
          ret.push(["<circle cx=\"" + x + "\" cy=\"0\" r=\"" + r + "\" fill=\"none\" stroke=\"" + c2 + "\" stroke-width=\"" + sw + "\">", "<animate attributeName=\"cy\" values=\"190;-10\" times=\"0;1\" ", "dur=\"" + d + "s\" begin=\"" + idx + "s\" repeatCount=\"indefinite\"/>", "</circle>", "<circle cx=\"" + x + "\" cy=\"0\" r=\"" + r + "\" fill=\"none\" stroke=\"" + c2 + "\" stroke-width=\"" + sw + "\">", "<animate attributeName=\"cy\" values=\"390;190\" times=\"0;1\" ", "dur=\"" + d + "s\" begin=\"" + idx + "s\" repeatCount=\"indefinite\"/>", "</circle>"].join(""));
        }
        return wrap(ret.join("") + "</svg>");
      }
    };
    handler = {
      queue: {},
      running: false,
      main: function(timestamp){
        var keepon, removed, k, ref$, func, ret, this$ = this;
        keepon = false;
        removed = [];
        for (k in ref$ = this.queue) {
          func = ref$[k];
          ret = func(timestamp);
          if (!ret) {
            removed.push(func);
          }
          keepon = keepon || ret;
        }
        for (k in ref$ = this.queue) {
          func = ref$[k];
          if (removed.indexOf(func) >= 0) {
            delete this.queue[k];
          }
        }
        if (keepon) {
          return requestAnimationFrame(function(it){
            return this$.main(it);
          });
        } else {
          return this.running = false;
        }
      },
      add: function(key, f){
        var this$ = this;
        if (!this.queue[key]) {
          this.queue[key] = f;
        }
        if (!this.running) {
          this.running = true;
          return requestAnimationFrame(function(it){
            return this$.main(it);
          });
        }
      }
    };
    window.ldBar = ldBar = function(selector, option){
      var xmlns, root, cls, idPrefix, id, domTree, newNode, x$, config, attr, that, isStroke, parseRes, dom, svg, text, group, length, path0, path1, patimg, img, ret, size, this$ = this;
      option == null && (option = {});
      xmlns = {
        xlink: "http://www.w3.org/1999/xlink"
      };
      root = toString$.call(selector).slice(8, -1) === 'String' ? document.querySelector(selector) : selector;
      if (!root.ldBar) {
        root.ldBar = this;
      } else {
        return root.ldBar;
      }
      cls = root.getAttribute('class') || '';
      if (!~cls.indexOf('ldBar')) {
        root.setAttribute('class', cls + " ldBar");
      }
      idPrefix = "ldBar-" + Math.random().toString(16).substring(2);
      id = {
        key: idPrefix,
        clip: idPrefix + "-clip",
        filter: idPrefix + "-filter",
        pattern: idPrefix + "-pattern",
        mask: idPrefix + "-mask",
        maskPath: idPrefix + "-mask-path"
      };
      domTree = function(n, o){
        var k, v;
        n = newNode(n);
        for (k in o) {
          v = o[k];
          if (k !== 'attr') {
            n.appendChild(domTree(k, v || {}));
          }
        }
        n.attrs(o.attr || {});
        return n;
      };
      newNode = function(n){
        return document.createElementNS("http://www.w3.org/2000/svg", n);
      };
      x$ = document.body.__proto__.__proto__.__proto__;
      x$.text = function(t){
        return this.appendChild(document.createTextNode(t));
      };
      x$.attrs = function(o){
        var k, v, ret, results$ = [];
        for (k in o) {
          v = o[k];
          ret = /([^:]+):([^:]+)/.exec(k);
          if (!ret || !xmlns[ret[1]]) {
            results$.push(this.setAttribute(k, v));
          } else {
            results$.push(this.setAttributeNS(xmlns[ret[1]], k, v));
          }
        }
        return results$;
      };
      x$.styles = function(o){
        var k, v, results$ = [];
        for (k in o) {
          v = o[k];
          results$.push(this.style[k] = v);
        }
        return results$;
      };
      x$.append = function(n){
        var r;
        try{
          return this.appendChild(r = document.createElementNS("http://www.w3.og/2000/svg", n));
        }
        catch (e) {
          console.log(e);
        }
      };
      x$.attr = function(n, v){
        if (v != null) {
          return this.setAttribute(n, v);
        } else {
          return this.getAttribute(n);
        }
      };
      config = {
        "type": 'stroke',
        "img": '',
        "path": 'M10 10L90 10M90 8M90 12',
        "fill-dir": 'btt',
        "fill": '#25b',
        "fill-background": '#ddd',
        "fill-background-extrude": 3,
        "pattern-size": null,
        "stroke-dir": 'normal',
        "stroke": '#25b',
        "stroke-width": '3',
        "stroke-trail": '#ddd',
        "stroke-trail-width": 0.5,
        "duration": 1,
        "easing": 'linear',
        "value": 0,
        "img-size": null,
        "bbox": null,
        "set-dim": true,
        "aspect-ratio": "xMidYMid",
        "transition-in": false,
        "min": 0,
        "max": 100,
        "precision": 0,
        "padding": undefined
      };
      config["preset"] = root.attr("data-preset") || option["preset"];
      if (config.preset != null) {
        import$(config, presets[config.preset]);
      }
      for (attr in config) {
        if (that = that = root.attr("data-" + attr)) {
          config[attr] = that;
        }
      }
      import$(config, option);
      if (config.img) {
        config.path = null;
      }
      isStroke = config.type === 'stroke';
      parseRes = function(v){
        var parser, ret;
        parser = /data:ldbar\/res,([^()]+)\(([^)]+)\)/;
        ret = parser.exec(v);
        if (!ret) {
          return v;
        }
        return ret = make[ret[1]].apply(make, ret[2].split(','));
      };
      config.fill = parseRes(config.fill);
      config.stroke = parseRes(config.stroke);
      if (config["set-dim"] === 'false') {
        config["set-dim"] = false;
      }
      dom = {
        attr: {
          "xmlns:xlink": 'http://www.w3.org/1999/xlink',
          preserveAspectRatio: config["aspect-ratio"],
          width: "100%",
          height: "100%"
        },
        defs: {
          filter: {
            attr: {
              id: id.filter,
              x: -1,
              y: -1,
              width: 3,
              height: 3
            },
            feMorphology: {
              attr: {
                operator: +config["fill-background-extrude"] >= 0 ? 'dilate' : 'erode',
                radius: Math.abs(+config["fill-background-extrude"])
              }
            },
            feColorMatrix: {
              attr: {
                values: '0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0',
                result: "cm"
              }
            }
          },
          mask: {
            attr: {
              id: id.mask
            },
            image: {
              attr: {
                "xlink:href": config.img,
                filter: "url(#" + id.filter + ")",
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                preserveAspectRatio: config["aspect-ratio"]
              }
            }
          },
          g: {
            mask: {
              attr: {
                id: id.maskPath
              },
              path: {
                attr: {
                  d: config.path || "",
                  fill: '#fff',
                  stroke: '#fff',
                  filter: "url(#" + id.filter + ")"
                }
              }
            }
          },
          clipPath: {
            attr: {
              id: id.clip
            },
            rect: {
              attr: {
                'class': 'mask',
                fill: '#000'
              }
            }
          },
          pattern: {
            attr: {
              id: id.pattern,
              patternUnits: 'userSpaceOnUse',
              x: 0,
              y: 0,
              width: 300,
              height: 300
            },
            image: {
              attr: {
                x: 0,
                y: 0,
                width: 300,
                height: 300
              }
            }
          }
        }
      };
      svg = domTree('svg', dom);
      text = document.createElement('div');
      text.setAttribute('class', 'ldBar-label');
      root.appendChild(svg);
      root.appendChild(text);
      group = [0, 0];
      length = 0;
      this.fit = function(){
        var that, box, d, rect;
        if (that = config["bbox"]) {
          box = that.split(' ').map(function(it){
            return +it.trim();
          });
          box = {
            x: box[0],
            y: box[1],
            width: box[2],
            height: box[3]
          };
        } else {
          box = group[1].getBBox();
        }
        if (!box || box.width === 0 || box.height === 0) {
          box = {
            x: 0,
            y: 0,
            width: 100,
            height: 100
          };
        }
        d = Math.max.apply(null, ['stroke-width', 'stroke-trail-width', 'fill-background-extrude'].map(function(it){
          return config[it];
        })) * 1.5;
        if (config["padding"] != null) {
          d = +config["padding"];
        }
        svg.attrs({
          viewBox: [box.x - d, box.y - d, box.width + d * 2, box.height + d * 2].join(" ")
        });
        if (config["set-dim"]) {
          ['width', 'height'].map(function(it){
            if (!root.style[it] || this$.fit[it]) {
              root.style[it] = (box[it] + d * 2) + "px";
              return this$.fit[it] = true;
            }
          });
        }
        rect = group[0].querySelector('rect');
        if (rect) {
          return rect.attrs({
            x: box.x - d,
            y: box.y - d,
            width: box.width + d * 2,
            height: box.height + d * 2
          });
        }
      };
      if (config.path) {
        if (isStroke) {
          group[0] = domTree('g', {
            path: {
              attr: {
                d: config.path,
                fill: 'none',
                'class': 'baseline'
              }
            }
          });
        } else {
          group[0] = domTree('g', {
            rect: {
              attr: {
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                mask: "url(#" + id.maskPath + ")",
                fill: config["fill-background"],
                'class': 'frame'
              }
            }
          });
        }
        svg.appendChild(group[0]);
        group[1] = domTree('g', {
          path: {
            attr: {
              d: config.path,
              'class': isStroke ? 'mainline' : 'solid',
              "clip-path": config.type === 'fill' ? "url(#" + id.clip + ")" : ''
            }
          }
        });
        svg.appendChild(group[1]);
        path0 = group[0].querySelector(isStroke ? 'path' : 'rect');
        path1 = group[1].querySelector('path');
        if (isStroke) {
          path1.attrs({
            fill: 'none'
          });
        }
        patimg = svg.querySelector('pattern image');
        img = new Image();
        img.addEventListener('load', function(){
          var box, that;
          box = (that = config["pattern-size"])
            ? {
              width: +that,
              height: +that
            }
            : img.width && img.height
              ? {
                width: img.width,
                height: img.height
              }
              : {
                width: 300,
                height: 300
              };
          svg.querySelector('pattern').attrs({
            width: box.width,
            height: box.height
          });
          return patimg.attrs({
            width: box.width,
            height: box.height
          });
        });
        if (/.+\..+|^data:/.exec(!isStroke
          ? config.fill
          : config.stroke)) {
          img.src = !isStroke
            ? config.fill
            : config.stroke;
          patimg.attrs({
            "xlink:href": img.src
          });
        }
        if (isStroke) {
          path0.attrs({
            stroke: config["stroke-trail"],
            "stroke-width": config["stroke-trail-width"]
          });
          path1.attrs({
            "stroke-width": config["stroke-width"],
            stroke: /.+\..+|^data:/.exec(config.stroke)
              ? "url(#" + id.pattern + ")"
              : config.stroke
          });
        }
        if (config.fill && !isStroke) {
          path1.attrs({
            fill: /.+\..+|^data:/.exec(config.fill)
              ? "url(#" + id.pattern + ")"
              : config.fill
          });
        }
        length = path1.getTotalLength();
        this.fit();
        this.inited = true;
      } else if (config.img) {
        if (config["img-size"]) {
          ret = config["img-size"].split(',');
          size = {
            width: +ret[0],
            height: +ret[1]
          };
        } else {
          size = {
            width: 100,
            height: 100
          };
        }
        group[0] = domTree('g', {
          rect: {
            attr: {
              x: 0,
              y: 0,
              width: 100,
              height: 100,
              mask: "url(#" + id.mask + ")",
              fill: config["fill-background"]
            }
          }
        });
        svg.querySelector('mask image').attrs({
          width: size.width,
          height: size.height
        });
        group[1] = domTree('g', {
          image: {
            attr: {
              width: size.width,
              height: size.height,
              x: 0,
              y: 0,
              preserveAspectRatio: config["aspect-ratio"],
              "clip-path": config.type === 'fill' ? "url(#" + id.clip + ")" : '',
              "xlink:href": config.img,
              'class': 'solid'
            }
          }
        });
        img = new Image();
        img.addEventListener('load', function(){
          var ret, size, v;
          if (config["img-size"]) {
            ret = config["img-size"].split(',');
            size = {
              width: +ret[0],
              height: +ret[1]
            };
          } else if (img.width && img.height) {
            size = {
              width: img.width,
              height: img.height
            };
          } else {
            size = {
              width: 100,
              height: 100
            };
          }
          svg.querySelector('mask image').attrs({
            width: size.width,
            height: size.height
          });
          group[1].querySelector('image').attrs({
            width: size.width,
            height: size.height
          });
          this$.fit();
          v = this$.value;
          this$.value = undefined;
          this$.set(v, true);
          return this$.inited = true;
        });
        img.src = config.img;
        svg.appendChild(group[0]);
        svg.appendChild(group[1]);
      }
      svg.attrs({
        width: '100%',
        height: '100%'
      });
      this.transition = {
        value: {
          src: 0,
          des: 0
        },
        time: {},
        ease: function(t, b, c, d){
          t = t / (d * 0.5);
          if (t < 1) {
            return c * 0.5 * t * t + b;
          }
          t = t - 1;
          return -c * 0.5 * (t * (t - 2) - 1) + b;
        },
        handler: function(time, doTransition){
          var ref$, min, max, prec, dv, dt, dur, v, p, node, style, box, dir;
          doTransition == null && (doTransition = true);
          if (this.time.src == null) {
            this.time.src = time;
          }
          ref$ = [config["min"], config["max"], 1 / config["precision"]], min = ref$[0], max = ref$[1], prec = ref$[2];
          ref$ = [this.value.des - this.value.src, (time - this.time.src) * 0.001, +config["duration"] || 1], dv = ref$[0], dt = ref$[1], dur = ref$[2];
          v = doTransition
            ? this.ease(dt, this.value.src, dv, dur)
            : this.value.des;
          if (config.precision) {
            v = Math.round(v * prec) / prec;
          } else if (doTransition) {
            v = Math.round(v);
          }
          v >= min || (v = min);
          v <= max || (v = max);
          text.textContent = v;
          p = 100.0 * (v - min) / (max - min);
          if (isStroke) {
            node = path1;
            style = {
              "stroke-dasharray": config["stroke-dir"] === 'reverse'
                ? "0 " + length * (100 - p) * 0.01 + " " + length * p * 0.01 + " 0"
                : p * 0.01 * length + " " + ((100 - p) * 0.01 * length + 1)
            };
          } else {
            box = group[1].getBBox();
            dir = config["fill-dir"];
            style = dir === 'btt' || !dir
              ? {
                y: box.y + box.height * (100 - p) * 0.01,
                height: box.height * p * 0.01,
                x: box.x,
                width: box.width
              }
              : dir === 'ttb'
                ? {
                  y: box.y,
                  height: box.height * p * 0.01,
                  x: box.x,
                  width: box.width
                }
                : dir === 'ltr'
                  ? {
                    y: box.y,
                    height: box.height,
                    x: box.x,
                    width: box.width * p * 0.01
                  }
                  : dir === 'rtl' ? {
                    y: box.y,
                    height: box.height,
                    x: box.x + box.width * (100 - p) * 0.01,
                    width: box.width * p * 0.01
                  } : void 8;
            node = svg.querySelector('rect');
          }
          node.attrs(style);
          if (dt >= dur) {
            delete this.time.src;
            return false;
          }
          return true;
        },
        start: function(src, des, doTransition){
          var ref$, this$ = this;
          ref$ = this.value;
          ref$.src = src;
          ref$.des = des;
          !!(root.offsetWidth || root.offsetHeight || root.getClientRects().length);
          if (!doTransition || !(root.offsetWidth || root.offsetHeight || root.getClientRects().length)) {
            this.time.src = 0;
            this.handler(1000, false);
            return;
          }
          return handler.add(id.key, function(time){
            return this$.handler(time);
          });
        }
      };
      this.set = function(v, doTransition){
        var src, des;
        doTransition == null && (doTransition = true);
        src = this.value || 0;
        if (v != null) {
          this.value = v;
        } else {
          v = this.value;
        }
        des = this.value;
        return this.transition.start(src, des, doTransition);
      };
      this.set(+config.value || 0, config["transition-in"]) || false;
      return this;
    };
    return window.addEventListener('load', function(){
      var i$, ref$, len$, node, results$ = [];
      for (i$ = 0, len$ = (ref$ = document.querySelectorAll('.ldBar')).length; i$ < len$; ++i$) {
        node = ref$[i$];
        if (!node.ldBar) {
          results$.push(node.ldBar = new ldBar(node));
        }
      }
      return results$;
    }, false);
  })();
  module.exports = ldBar;
  function import$(obj, src){
    var own = {}.hasOwnProperty;
    for (var key in src) if (own.call(src, key)) obj[key] = src[key];
    return obj;
  }
  
  },{"./presets":2}],2:[function(require,module,exports){
  // Generated by LiveScript 1.3.1
  var presets, out$ = typeof exports != 'undefined' && exports || this;
  out$.presets = presets = {
    rainbow: {
      "type": 'stroke',
      "path": 'M10 10L90 10',
      "stroke": 'data:ldbar/res,gradient(0,1,#a551df,#fd51ad,#ff7f82,#ffb874,#ffeb90)',
      "bbox": "10 10 80 10"
    },
    energy: {
      "type": 'fill',
      "path": 'M15 5L85 5A5 5 0 0 1 85 15L15 15A5 5 0 0 1 15 5',
      "stroke": '#f00',
      "fill": 'data:ldbar/res,gradient(45,2,#4e9,#8fb,#4e9)',
      "fill-dir": "ltr",
      "fill-background": '#444',
      "fill-background-extrude": 1,
      "bbox": "10 5 80 10"
    },
    stripe: {
      "type": 'fill',
      "path": 'M15 5L85 5A5 5 0 0 1 85 15L15 15A5 5 0 0 1 15 5',
      "stroke": '#f00',
      "fill": 'data:ldbar/res,stripe(#25b,#58e,1)',
      "fill-dir": "ltr",
      "fill-background": '#ddd',
      "fill-background-extrude": 1,
      "bbox": "10 5 80 10"
    },
    text: {
      "type": 'fill',
      "img": "data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"70\" height=\"20\" viewBox=\"0 0 70 20\"><text x=\"35\" y=\"10\" text-anchor=\"middle\" dominant-baseline=\"central\" font-family=\"arial\">LOADING</text></svg>",
      "fill-background-extrude": 1.3,
      "pattern-size": 100,
      "fill-dir": "ltr",
      "img-size": "70,20",
      "bbox": "0 0 70 20"
    },
    line: {
      "type": 'stroke',
      "path": 'M10 10L90 10',
      "stroke": '#25b',
      "stroke-width": 3,
      "stroke-trail": '#ddd',
      "stroke-trail-width": 1,
      "bbox": "10 10 80 10"
    },
    fan: {
      "type": 'stroke',
      "path": 'M10 90A40 40 0 0 1 90 90',
      "fill-dir": 'btt',
      "fill": '#25b',
      "fill-background": '#ddd',
      "fill-background-extrude": 3,
      "stroke-dir": 'normal',
      "stroke": '#25b',
      "stroke-width": '3',
      "stroke-trail": '#ddd',
      "stroke-trail-width": 0.5,
      "bbox": "10 50 80 40"
    },
    circle: {
      "type": 'stroke',
      "path": 'M50 10A40 40 0 0 1 50 90A40 40 0 0 1 50 10',
      "fill-dir": 'btt',
      "fill": '#25b',
      "fill-background": '#ddd',
      "fill-background-extrude": 3,
      "stroke-dir": 'normal',
      "stroke": '#25b',
      "stroke-width": '3',
      "stroke-trail": '#ddd',
      "stroke-trail-width": 0.5,
      "bbox": "10 10 80 80"
    },
    bubble: {
      "type": 'fill',
      "path": 'M50 10A40 40 0 0 1 50 90A40 40 0 0 1 50 10',
      "fill-dir": 'btt',
      "fill": 'data:ldbar/res,bubble(#39d,#cef)',
      "pattern-size": "150",
      "fill-background": '#ddd',
      "fill-background-extrude": 2,
      "stroke-dir": 'normal',
      "stroke": '#25b',
      "stroke-width": '3',
      "stroke-trail": '#ddd',
      "stroke-trail-width": 0.5,
      "bbox": "10 10 80 80"
    }
  };
  
  },{}]},{},[1]);